
import { extractLanguageFromPathname } from "./helper";
import getCookie from "./cookie";

function getLang() {
  const searchParams = new URLSearchParams(window?.location?.search) ?? "";
  const pathLang =
    extractLanguageFromPathname(window.location.pathname) ?? null;
  const cookieLang = getCookie("lang");
  const language = pathLang ?? searchParams?.get("lang") ?? cookieLang ?? "en";
  const domain = /local/.test(window.location.host)
    ? "localhost"
    : "moneris.com";
  document.cookie = `lang=${language}; expires=Thu, 18 Dec 2032 12:00:00 UTC;domain=${domain};path=/`;
  window.localStorage.setItem("lang", language.toLowerCase());
  return language;
}

export default getLang;
