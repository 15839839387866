/* eslint-disable */
import React from "react";
import createTranslator from "../../util/translationHelper";
import { useHistory } from "react-router-dom";
import { PrimaryButton } from "@moneris-portal/button";
import messages from "./Translations/messages";

import "./index.scss";

function NotFound({ lang }) {
  const history = useHistory();

  const handleHomeNavigation = () => {
    history.push(`/${lang}/home`);
  };

  const translate = createTranslator(messages, lang);
  return (
    <div className="not_found--container">
        <div className="not_found--body">
          <h2 class="text-center">{translate("page_moved")}</h2>
          <PrimaryButton
            title={translate("home")}
            clickHandler={handleHomeNavigation}
          />
        </div>
    </div>
  );
}

NotFound.defaultProps = {
  // message: null
};

NotFound.propTypes = {
  // message: PropTypes.any
};

export default NotFound;
