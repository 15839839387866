const messages = {
  en: {
    resources: 'Resources',

    start_a_digital_storefront: "'Start a digital storefront' e-book",
    build_your_online_business: 'Build your online business with our step-by-step guide.',
    download_free_today: 'Download free today',
    start_a_digital_storefront_link: 'https://go.moneris.com/ecommguide',

    industry_insights_blog: 'Industry insights blog',
    stay_informed:
      'Stay informed on the latest industry knowledge and practical tips from our experts.',
    learn_now: 'Learn now',
    industry_insights_blog_link: 'https://www.moneris.com/en/insights',

    exclusive_time_limited_offer: 'Exclusive time-limited offer',
    boost_your_sales: 'Boost your sales and enhance your payment security and flexibility.',
    unlock_your_offer: 'Unlock your offer',
    exclusive_time_limited_offer_link: 'https://go.moneris.com/giftcardoffer-is',
  },
  fr: {
    resources: 'Ressources',

    start_a_digital_storefront:
      '‘Créez une nouvelle vitrine commerciale en ligne’ (livre électronique)',
    build_your_online_business: 'Créez votre commerce en ligne grâce à notre guide détaillé.',
    download_free_today: "Télécharger le guide gratuit aujourd'hui",
    start_a_digital_storefront_link: 'https://go.moneris.com/guidecommerceelectronique',

    industry_insights_blog: 'Blogue de ressources sur l’industrie',
    stay_informed:
      'Restez au courant des plus récentes tendances de l’industrie et obtenez des conseils pratiques de nos experts.',
    learn_now: 'Apprendre maintenant',
    industry_insights_blog_link: 'https://www.moneris.com/fr-ca/ressources',

    exclusive_time_limited_offer: 'Offre d’une durée limitée exclusive',
    boost_your_sales:
      'Augmentez vos ventes et améliorez la sécurité et la flexibilité de vos paiements.',
    unlock_your_offer: 'Profiter de l’offre',
    exclusive_time_limited_offer_link: 'https://go.moneris.com/giftcardoffer-is',
  },
};

export default messages;
