import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ChevronDown from '../../SvgComponents/ChevronDown';
import ChevronUp from '../../SvgComponents/ChevronUp';
import './index.scss';
/* eslint-disable */
const HeaderNavMenu = ({ title, optionList, handleOptionSelected, style }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuVisibility = () => {
    setMenuOpen(!menuOpen);
  };

  const hideMenuVisibility = () => {
    setMenuOpen(false);
  };

  const handleNavMainKeyDown = event => {
    if (event.keyCode === 13) {
      handleMenuVisibility();
    }
  };

  const handleNavOptionKeyDown = (event, menuValue) => {
    if (event.keyCode === 13) {
      hideMenuVisibility();
      handleOptionSelected(menuValue);
    }
  };

  return (
    <div className='header_nav_container'>
      {/* eslint-disable jsx-a11y/click-events-have-key-events */}
      <div
        tabIndex={0}
        className='header_nav_main'
        onClick={handleMenuVisibility}
        onKeyDown={handleNavMainKeyDown}
      >
        <span>{title}</span>
        {menuOpen && !!optionList.length && <ChevronUp color='#fff' />}
        {!menuOpen && !!optionList.length && <ChevronDown color='#fff' />}
      </div>
      {menuOpen && !!optionList.length && (
        <div role='navigation' className='header_nav_options' style={style}>
          <ul onClick={hideMenuVisibility}>
            {optionList.map((val) => {
              return (
                <li
                  key={`header_nav_options_${val.Id}`}
                  tabIndex={0}
                  onClick={() => handleOptionSelected(val)}
                  onKeyDown={event => handleNavOptionKeyDown(event, val)}
                >
                  {val.option}
                </li>
              );
            })}
          </ul>
          <div className='header_nav_overlay' onClick={hideMenuVisibility} />
        </div>
      )}
    </div>
  );
};

HeaderNavMenu.propTypes = {
  title: PropTypes.string,
  handleOptionSelected: PropTypes.func,
  optionList: PropTypes.array,
  style: PropTypes.any
};

HeaderNavMenu.defaultProps = {
  title: '',
  handleOptionSelected: () => null,
  optionList: [],
  style: {}
};

export default HeaderNavMenu;
