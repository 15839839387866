import React from "react";
import PropTypes from "prop-types";

const PencilLogo = ({ width, height, color }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_143_2711)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.5931 -0.000387684C20.1729 -0.00431301 20.7476 0.106673 21.2842 0.326158C21.8207 0.545643 22.3085 0.869276 22.7193 1.27836L22.19 1.80978L22.7214 1.28054C23.1305 1.69131 23.4542 2.17907 23.6736 2.71563C23.8931 3.2522 24.0041 3.82694 24.0002 4.40665C23.9963 4.98637 23.8775 5.55955 23.6508 6.0931C23.4246 6.62524 23.0956 7.10743 22.6825 7.51196L8.18535 22.0091C8.09032 22.1041 7.97152 22.1719 7.84135 22.2053L0.936346 23.9763C0.679581 24.0421 0.407123 23.9675 0.219686 23.7801C0.0322492 23.5927 -0.0423246 23.3202 0.0235308 23.0634L1.79453 16.1584C1.82792 16.0283 1.89566 15.9095 1.99069 15.8144L16.4878 1.31738C16.8923 0.904269 17.3745 0.575175 17.9067 0.349027C18.4402 0.122296 19.0134 0.00353758 19.5931 -0.000387684ZM21.6598 2.34018C21.3896 2.07142 21.069 1.85877 20.7163 1.7145C20.3631 1.57004 19.9848 1.49699 19.6033 1.49958C19.2218 1.50216 18.8445 1.58032 18.4933 1.72955C18.1422 1.87877 17.8241 2.0961 17.5574 2.36898L17.5514 2.37514L3.1965 16.7299L1.79138 22.2084L7.26984 20.8033L21.6308 6.44236C21.9037 6.17568 22.121 5.8576 22.2702 5.50644C22.4195 5.15528 22.4976 4.77804 22.5002 4.3965C22.5028 4.01496 22.4298 3.63668 22.2853 3.28354C22.141 2.93089 21.9285 2.61029 21.6598 2.34018Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.0757 1.72945C16.3686 1.43655 16.8435 1.43655 17.1363 1.72945L22.2703 6.86345C22.5632 7.15634 22.5632 7.63121 22.2703 7.92411C21.9775 8.217 21.5026 8.217 21.2097 7.92411L16.0757 2.79011C15.7828 2.49721 15.7828 2.02234 16.0757 1.72945Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.9817 3.82345C14.2746 3.53055 14.7495 3.53055 15.0423 3.82345L20.1763 8.95745C20.4692 9.25034 20.4692 9.72521 20.1763 10.0181C19.8835 10.311 19.4086 10.311 19.1157 10.0181L13.9817 4.88411C13.6888 4.59121 13.6888 4.11634 13.9817 3.82345Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.99069 15.8144C2.28329 15.5213 2.75765 15.5213 3.05083 15.8139L8.18983 20.9429C8.48301 21.2355 8.48347 21.7104 8.19086 22.0036C7.89825 22.2968 7.42338 22.2972 7.1302 22.0046L1.9912 16.8756C1.69802 16.583 1.69808 16.1076 1.99069 15.8144Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_143_2711">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

PencilLogo.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

PencilLogo.defaultProps = {
  width: "24",
  height: "24",
  color: "#007191",
};

export default PencilLogo;
