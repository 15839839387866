import React from "react";
import { PrimaryButton } from "@moneris-portal/button";
import "./index.scss";

function EncourageSetup(props) {
  const { history, handleShowStep  } = props;
  const lang = window.localStorage?.getItem('lang') ?? 'en';
  const handleNavigation = () => {
    handleShowStep();
    history.push(`/${lang}/setting?action=Security`);
  };

  return (
    <div id="setting-step">
      <div>
        <div>
          <h3>Two-Factor Authentication Update</h3>
          <p>
            In order to increase the security of your Single Sign On account, we
            highly recommend that you select stronger 2FA method on the
            following screen
          </p>
          <p>Click on the button below to get started.</p>
          <PrimaryButton title={"Update now"} clickHandler={handleNavigation} />
        </div>
        <p onClick={() => handleShowStep()}>Do it later</p>
      </div>
    </div>
  );
}

export default EncourageSetup;
