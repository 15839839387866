const messages = {
  en: {
    useful_links: "Useful links",
  },
  fr: {
    useful_links: "Liens utiles",
  },
};

export default messages;
