import QRCode from 'qrcode';

function QrCodeGenerator(cb, secret, username) {
  const opts = {
    errorCorrectionLevel: 'H',
    type: 'image/jpeg',
    quality: 0.3,
    margin: 1,
    color: {
      dark: '#051D33',
      light: '#ffffff'
    }
  };
  const formattedMessage = secret;

  if (secret)
    QRCode.toDataURL(formattedMessage, opts, function(err, url) {
      if (err) throw err;
      cb(err, url);
    });
    else{
      cb('missing secret',null)
    }
}

export default QrCodeGenerator;
