const footerLinks = {
  en: [
    {
      title: 'Moneris.com',
      url: 'http://www.moneris.com/en',
    },
    {
      title: 'Terms of Use',
      url: 'https://www.moneris.com/en/legal/Terms-of-Use',
    },
    {
      title: 'Privacy Policy',
      url: ' https://www.moneris.com/en/Legal/Privacy-Notice',
    },
    {
      title: 'Support',
      url: 'https://www.moneris.com/en/support/products/merchant-direct',
    },
  ],
  fr: [
    {
      title: 'Moneris.com',
      url: 'https://www.moneris.com/fr-ca/',
    },
    {
      title: "Conditions d'utilisation",
      url: 'https://www.moneris.com/fr-ca/juridiques/conditions-utilisation/',
    },
    {
      title: 'Politique de confidentialité',
      url: 'https://www.moneris.com/fr-CA/Juridiques/Avis-protection-reseignements-personnels',
    },
    {
      title: 'Soutien',
      url: 'https://www.moneris.com/fr-ca/soutien/produits/marchand-direct',
    },
  ],
};

// The pathnames that the footer shouldn't be sticky
// Onboarding shouldn't be sticky according to Figma
const stickyBlacklist = ['/onboarding'];

export { footerLinks, stickyBlacklist };
