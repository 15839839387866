import React from "react";
import ReactDOM from "react-dom";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import { AppStateProvider } from "./contextProviders/index";
import App from "./App.jsx";
import { MsalProvider } from "@azure/msal-react";

import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/index.css";



/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */


const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <AppStateProvider>
        <App />
      </AppStateProvider>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
