import React from 'react';
import PropTypes from 'prop-types';

/* eslint-disable */

export default function ChevronDown(props) {
  const { width, height, color } = props;
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height}>
      <path
        d='M6 6.694a.693.693 0 01-.492-.2l-5.3-5.3a.695.695 0 01.983-.983L6 5.017 10.814.204a.695.695 0 01.983.983l-5.305 5.3A.692.692 0 016 6.694z'
        fill={color}
      />
    </svg>
  );
}

ChevronDown.propTypes = {
  width: PropTypes.string,
  heigth: PropTypes.string,
  color: PropTypes.string
};

ChevronDown.defaultProps = {
  width: '12',
  height: '6.6',
  color: '#051d33'
};
