/**
 * Author: Davinder Sandhu
 * Last edit: Brian Yang
 * Edit date: Dec 2, 2020
 */

const monthmatrix = {
  0: "January",
  1: "Feburary",
  2: "March",
  3: "April",
  4: "May",
  5: "June",
  6: "July",
  7: "August",
  8: "September",
  9: "October",
  10: "November",
  11: "December",
};
const daymatrix = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
};

const DateFormatter = {
  getDate(val) {
    if (val !== null && val !== undefined && val !== "") {
      const isNum = /^\d+$/.test(val);
      const date = isNum ? new Date(Number.parseInt(val, 10)) : new Date(val);
      if (date.toDateString() === "Invalid Date") return "Invalid Date";
      const month = date.getMonth();
      const day = date.getDate();
      const year = date.getFullYear();
      return `${monthmatrix[month].toString()} ${day}, ${year}`;
    }
    return "Invalid Date";
  },
  getDaysBetweenDates(val) {
    if (val) {
      const date = new Date(val);
      if (date.toDateString() === "Invalid Date") return "Invalid Date";
      return Math.round((date - new Date()) / (1000 * 60 * 60 * 24));
    }
    return "Invalid Date";
  },
  getMaxDate(date1str, date2str) {
    const date1 = new Date(date1str);
    const date2 = new Date(date2str);
    if (
      date1.toDateString() === "Invalid Date" ||
      date2.toDateString() === "Invalid Date"
    )
      return "Invalid Date";
    return date1.getTime() >= date2.getTime() ? date1 : date2;
  },
  getMinDate(date1str, date2str) {
    const date1 = new Date(date1str);
    const date2 = new Date(date2str);
    if (
      date1.toDateString() === "Invalid Date" ||
      date2.toDateString() === "Invalid Date"
    )
      return "Invalid Date";
    return date1.getTime() >= date2.getTime() ? date2 : date1;
  },
  getDayString(val) {
    const date = new Date(val);
    const day = date.getDay();
    return daymatrix[day];
  },
  getMonthString(val) {
    const date = new Date(val);
    const month = date.getMonth();
    return monthmatrix[month];
  },
  getSecondsdiff(val) {
    const date1 = new Date(val);
    const diff = new Date().getTime() - date1.getTime();
    return diff;
  },
  /**
   * @param {string} val Full date string
   * @param {('24'|'12')} hourFormat - default 24 hours
   * @returns {('hh:mm:ss'|'hh:mm:ss (am|pm)')}
   */
  getTime(val, hourFormat = "24") {
    const date = new Date(val);
    const hoursFull = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();
    let hours = hoursFull;
    let ampm = "";

    if (hourFormat === "12") {
      hours = hoursFull > 12 ? hoursFull - 12 : hoursFull;
      ampm = hoursFull > 12 ? "pm" : "am";
    }

    if (minutes <= 10) {
      minutes = `0${minutes}`;
    }

    if (seconds <= 10) {
      seconds = `0${seconds}`;
    }

    return `${hours}:${minutes}:${seconds} ${
      hourFormat === "12" ? ampm : ""
    }`.trim();
  },
  getDateinFuture(milliseconds){
    const date = new Date();
    date.setTime(date.getTime() + milliseconds /*days*/);  
    return date.toUTCString();
  }
};

export default DateFormatter;
