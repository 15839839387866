import React from "react";
import ResponsiveLink from "@moneris-portal/responsive-link";
import messages from "./translations/messages";
import createTranslator from "../../../util/translationHelper";

import "./index.scss";

const usefulLinksConfig = [
  {
    title: "fraud_prevention_101",
    text: "tips_and_tactics",
    link: "fraud_prevention_101_link",
  },
  {
    title: "contact_us",
    text: "talk_to_business",
    link: "contact_us_link",
  },
];

const UsefulLinks = ({ loading = false }) => {
  const translate = createTranslator(messages);

  return (
    <div className="useful_links_container">
      {usefulLinksConfig.map(({ title, link, text }) => (
        <div className="useful_links_set" key={title}>
          <ResponsiveLink
            linkText={translate(title)}
            link={translate(link)}
            textStyle={{ fontSize: "1rem" }}
            isDisabled={loading}
            linkAnchorAttributes={{ rel: "noreferrer", target: "_blank" }}
          />
          <p className="useful_links_subtitle">{translate(text)}</p>
        </div>
      ))}
    </div>
  );
};

export default UsefulLinks;
