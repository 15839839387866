const messages = {
  en: {
    useful_links: "Useful links",
    fraud_prevention_101: "Fraud prevention 101",
    tips_and_tactics: "Tips and tactics to prevent fraud.",
    fraud_prevention_101_link:
      "https://www.moneris.com/en/insights/posts/fraud-prevention/fraud-prevention-101-tips-and-tactics-to-prevent-fraud?category=%7b73F11F64-E5E9-451A-B1FD-050A32A987C3%7d",
    contact_us: "Contact us",
    talk_to_business: "Talk to business advisers for additional support.",
    contact_us_link: "https://www.moneris.com/en/support/contact",
  },
  fr: {
    useful_links: "Liens utiles",
    fraud_prevention_101: "Protection contre la fraude 101",
    tips_and_tactics: "Conseils et tactiques pour prévenir la fraude.",
    fraud_prevention_101_link:
      "https://www.moneris.com/fr-ca/ressources/posts/prevention-de-la-fraude/protection-contre-la-fraude-101-conseils-et-tactiques-pour-prevenir-la-fraude?category=%7B73F11F64-E5E9-451A-B1FD-050A32A987C3%7D",
    contact_us: "Nous joindre",
    talk_to_business:
      "Discutez avec l’un de nos conseillers d’affaires pour obtenir du soutien supplémentaire.",
    contact_us_link: "https://www.moneris.com/fr-ca/soutien/contact",
  },
};

export default messages;
