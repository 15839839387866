import React, { useState, useEffect, useCallback } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";
import ResponsiveModal from "@moneris-portal/responsive-modal";
import { TextInput } from "@moneris-portal/input";

import { isEqual } from "../../../../../util/object-parser";
import { validation, FIELD_ENUM } from "./utils";
import PhoneValidator from "../../../../../util/phoneValidator";

import createTranslator from "../../../../../util/translationHelper";
import messages from "../../Translations/messages";

import "./index.scss";

const VerifyModal = ({
  showModal,
  handleModalVisibility,
  setExpiredCodeNotification,
  validatePin,
  smsPhone,
  mfaMethod,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const translate = createTranslator(messages);

  const initialFormValues = {
    [FIELD_ENUM.mfaCode]: "",
  };

  const getValidationShape = useCallback(() => {
    const validationShape = {
      [FIELD_ENUM.mfaCode]: validation[FIELD_ENUM.mfaCode],
    };

    return Yup.object().shape(validationShape);
  }, []);

  useEffect(() => {
    const expireFunc = setTimeout(() => {
      handleModalVisibility();
      setExpiredCodeNotification();
    }, 600000);

    return clearInterval(expireFunc);
  }, []);

  const onSubmit = () => {
    const { mfaCode } = values;
    setSubmitting(true);
    validatePin({ mfa_method: mfaMethod, mfa_code: mfaCode });
  };

  const {
    values,
    touched,
    errors,
    setFieldValue,
    handleSubmit,
    isValid,
    setFieldTouched,
  } = useFormik({
    initialValues: initialFormValues,
    onSubmit,
    validationSchema: getValidationShape(),
    enableReinitialize: true,
    validateOnMount: true,
  });

  const isFormDisabled = !isValid || isEqual(values, initialFormValues);

  return (
    <ResponsiveModal
      show={showModal}
      onClose={handleModalVisibility}
      position="right"
      primaryButtonConfig={{
        title: submitting ? (
          <div className="button_content">
            <div> {translate("verify_code")}</div>
            <div className="spinner" />
          </div>
        ) : (
          translate("verify_code")
        ),
        type: "submit",
        isDisabled: isFormDisabled || submitting,
        clickHandler: handleSubmit,
      }}
      secondaryButtonConfig={{
        title: translate("cancel"),
        type: "button",
        clickHandler: handleModalVisibility,
      }}
      title={
        mfaMethod === "phone"
          ? translate("mobile_two_fa_setup")
          : translate("verify_email")
      }
      id="verify_container"
    >
      <p>
        {`${translate("sent_code")} ${PhoneValidator(smsPhone)}. ${translate(
          "copy_the_verification_code"
        )}`}
        <br />
        <br />
        <span className="strong">{translate("note")}</span>{" "}
        {translate("code_expires")}
      </p>

      <form onSubmit={handleSubmit}>
        <TextInput
          changeHandler={(value) => setFieldValue(FIELD_ENUM.mfaCode, value)}
          blurHandler={() => setFieldTouched(FIELD_ENUM.mfaCode, true)}
          autocomplete="off"
          maxLength={15}
          errorMessage={
            touched[FIELD_ENUM.mfaCode] && !!errors[FIELD_ENUM.mfaCode]
              ? translate(errors[FIELD_ENUM.mfaCode])
              : null
          }
          name={FIELD_ENUM.mfaCode}
          id={FIELD_ENUM.mfaCode}
          value={values[FIELD_ENUM.mfaCode]}
          label={translate("verification_code")}
        />
      </form>
    </ResponsiveModal>
  );
};

VerifyModal.defaultProps = {
  showModal: false,
};

VerifyModal.propTypes = {
  showModal: PropTypes.bool,
  handleModalVisibility: PropTypes.func.isRequired,
  smsPhone: PropTypes.string.isRequired,
  validatePin: PropTypes.func.isRequired,
  setExpiredCodeNotification: PropTypes.func.isRequired,
  mfaMethod: PropTypes.string.isRequired,
};

export default VerifyModal;
