/* eslint-disable */
import React from "react";
import createTranslator from "../../util/translationHelper";
import Modal from "@moneris-portal/modal";
import { PrimaryButton } from "@moneris-portal/button";
import messages from "./Translations/messages";
import "./index.scss";

function PageError({ lang }) {
  const handleVisibility = () => null;

  const handleRefresh = () => {
    window.location.reload(true);
  };

  const translate = createTranslator(messages, lang);
  return (
    <div className="page_error--container">
      <Modal
        title={translate("title")}
        show
        showClose={false}
        handleVisibility={handleVisibility}
        type="md"
      >
        <div className="page_error--body">
          <p>
            <span>
              {translate("wrong")} <br /> {translate("try_again")}
            </span>
          </p>
          <PrimaryButton
            title={translate("try_again_button")}
            clickHandler={handleRefresh}
          />
        </div>
      </Modal>
    </div>
  );
}

PageError.defaultProps = {
  // message: null
};

PageError.propTypes = {
  // message: PropTypes.any
};

export default PageError;
