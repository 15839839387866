import React from "react";

const LinkIcon = ({ color = "#007191", width = 24, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.2868 3.9025C20.3549 3.97229 20.4067 4.05208 20.4422 4.13707C20.4794 4.22607 20.5 4.32377 20.5 4.42627V8.92627C20.5 9.34048 20.1642 9.67627 19.75 9.67627C19.3358 9.67627 19 9.34048 19 8.92627V6.23693L10.2803 14.9566C9.98744 15.2495 9.51256 15.2495 9.21967 14.9566C8.92678 14.6637 8.92678 14.1888 9.21967 13.8959L17.9393 5.17627H15.25C14.8358 5.17627 14.5 4.84048 14.5 4.42627C14.5 4.01206 14.8358 3.67627 15.25 3.67627H19.75C19.9538 3.67627 20.1386 3.75755 20.2738 3.88947L20.2868 3.9025ZM5.75 6.67627C5.28587 6.67627 4.84075 6.86064 4.51256 7.18883C4.18437 7.51702 4 7.96214 4 8.42627V18.4263C4 18.8904 4.18437 19.3355 4.51256 19.6637C4.84075 19.9919 5.28587 20.1763 5.75 20.1763H15.75C16.2141 20.1763 16.6592 19.9919 16.9874 19.6637C17.3156 19.3355 17.5 18.8904 17.5 18.4263V12.4263C17.5 12.0121 17.1642 11.6763 16.75 11.6763C16.3358 11.6763 16 12.0121 16 12.4263V18.4263C16 18.4926 15.9737 18.5562 15.9268 18.603C15.8799 18.6499 15.8163 18.6763 15.75 18.6763H5.75C5.68369 18.6763 5.62011 18.6499 5.57322 18.603C5.52634 18.5562 5.5 18.4926 5.5 18.4263V8.42627C5.5 8.35997 5.52634 8.29638 5.57322 8.24949C5.62011 8.20261 5.6837 8.17627 5.75 8.17627H11.75C12.1642 8.17627 12.5 7.84048 12.5 7.42627C12.5 7.01206 12.1642 6.67627 11.75 6.67627H5.75Z"
        fill={color}
      />
    </svg>
  );
};

export default LinkIcon;
