import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import * as Yup from "yup";
import copy from "copy-to-clipboard";
import { usePrevious } from "@moneris-portal/package-helpers";
import { PrimaryButton } from "@moneris-portal/button";
import ResponsiveModal from "@moneris-portal/responsive-modal";
import { TextInput } from "@moneris-portal/input";
import { SmoothCopyIcon } from "@moneris-portal/svg-components";
import { isEqual } from "../../../../../util/object-parser";
import { validation, FIELD_ENUM } from "./utils";
import QrCodeGenerator from "../../../../Library/QrCodeGenerator";

import createTranslator from "../../../../../util/translationHelper";
import messages from "../../Translations/messages";

import "./index.scss";

const AuthSetUpModal = ({
  showModal,
  handleModalVisibility,
  validatePin,
  fetchTotpSecret,
  totpSecret,
  totpSecretFetchErrorFlag,
  userName,
  otpUri,
}) => {
  const prevProps = usePrevious({ totpSecret });

  const [submitting, setSubmitting] = useState(false);
  const [qrUrl, setQrUrl] = useState(null);

  useEffect(() => {
    fetchTotpSecret();
  }, []);

  const getQrCode = (err, urlArg) => {
    if (err) {
      console.error(err);
    }

    setQrUrl(urlArg);
  };

  useEffect(() => {
    if (prevProps?.totpSecret !== totpSecret) {
      QrCodeGenerator(getQrCode, otpUri, userName);
    }
  }, [totpSecret]);

  const initialFormValues = {
    [FIELD_ENUM.pin]: "",
  };

  const getValidationShape = useCallback(() => {
    const validationShape = {
      pin: validation[FIELD_ENUM.pin],
    };

    return Yup.object().shape(validationShape);
  }, []);

  const onSubmit = () => {
    const { pin } = values;
    setSubmitting(true);
    validatePin({ pin, method: "app" });
  };

  const {
    values,
    touched,
    errors,
    setFieldValue,
    handleSubmit,
    isValid,
    setFieldTouched,
  } = useFormik({
    initialValues: initialFormValues,
    onSubmit,
    validationSchema: getValidationShape(),
    enableReinitialize: true,
    validateOnMount: true,
  });

  const copyToClipBoardHandler = () => {
    copy(totpSecret);
  };

  const isFormDisabled =
    !isValid || isEqual(values, initialFormValues) || !qrUrl || qrUrl === "";
  const translate = createTranslator(messages);

  return (
    <ResponsiveModal
      show={showModal}
      onClose={handleModalVisibility}
      position="right"
      primaryButtonConfig={{
        title: submitting ? (
          <div className="button_content">
            <div> {translate("verify_code")}</div>
            <div className="spinner" />
          </div>
        ) : (
          translate("verify_code")
        ),
        type: "submit",
        isDisabled: isFormDisabled || submitting,
        clickHandler: handleSubmit,
      }}
      secondaryButtonConfig={{
        title: translate("cancel"),
        type: "button",
        clickHandler: handleModalVisibility,
      }}
      title={translate("two_fa_setup")}
      id="auth_setup_modal"
    >
      <div>
        <p>{translate("authenticator_app_setup_line1")}</p>
      </div>

      <div className="step_container">
        <h5>{translate("step1")}</h5>
        <p>
          {translate("step1_text1")} <br />
          {translate("step1_text2")}
        </p>
      </div>

      <div className="step_container">
        <h5>{translate("step2")}</h5>
        <p>{translate("step2_text1")}</p>
        <div className="qr_code">
          {qrUrl && <img alt="Scan this QR code" src={qrUrl} />}
          {!qrUrl && <div className="spinner" />}
        </div>
        <div>
          <p>{translate("step2_text2")}</p>
        </div>
        {totpSecret && (
          <div className="qr_code_button_row">
            <TextInput isDisabled value={totpSecret} />
            <div>
              <PrimaryButton
                clickHandler={copyToClipBoardHandler}
                title={translate("copy")}
                isDisabled={totpSecret === ""}
              >
                <SmoothCopyIcon />
              </PrimaryButton>
            </div>
          </div>
        )}
      </div>

      <div className="step_container">
        <form onSubmit={handleSubmit}>
          <h5>{translate("step3")}</h5>
          <p>{translate("step3_text1")}</p>

          <div className="token-button">
            <TextInput
              changeHandler={(value) => setFieldValue(FIELD_ENUM.pin, value)}
              blurHandler={() => setFieldTouched(FIELD_ENUM.pin, true)}
              autocomplete="off"
              maxLength={15}
              errorMessage={
                touched[FIELD_ENUM.pin] && !!errors[FIELD_ENUM.pin]
                  ? translate(errors[FIELD_ENUM.pin])
                  : null
              }
              isDisabled={totpSecretFetchErrorFlag}
              name={FIELD_ENUM.pin}
              id={FIELD_ENUM.pin}
              value={values[FIELD_ENUM.pin]}
              label={translate("verification_code")}
            />
          </div>
        </form>
      </div>
    </ResponsiveModal>
  );
};

AuthSetUpModal.defaultProps = {
  showModal: false,
  errorCode: null,
  validatePinSuccessFlag: null,
  totpSecretFetchErrorFlag: null,
};

AuthSetUpModal.propTypes = {
  showModal: PropTypes.bool,
  handleModalVisibility: PropTypes.func.isRequired,
  totpSecret: PropTypes.string.isRequired,
  validatePinSuccessFlag: PropTypes.oneOf([null, true, false]),
  validatePin: PropTypes.func.isRequired,
  totpSecretFetchErrorFlag: PropTypes.oneOf([null, true, false]),
  errorCode: PropTypes.any,
  user_name: PropTypes.string.isRequired,
  fetchTotpSecret: PropTypes.func.isRequired,
};

export default AuthSetUpModal;
