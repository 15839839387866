import React, { useCallback, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import ResponsiveModal from "@moneris-portal/responsive-modal";
import PropTypes from "prop-types";
import { TextInput } from "@moneris-portal/input";
import { isEqual } from "../../../../../util/object-parser";
import { validation, FIELD_ENUM } from "./utils";
import createTranslator from "../../../../../util/translationHelper";
import messages from "../../Translations/messages";

import "./index.scss";

const EditModalPhone = ({
  showModal,
  handleModalVisibility,
  updateUserPhone,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const translate = createTranslator(messages);

  const initialFormValues = {
    [FIELD_ENUM.phoneNumber]: "",
  };

  const getValidationShape = useCallback(() => {
    const validationShape = {
      [FIELD_ENUM.phoneNumber]: validation[FIELD_ENUM.phoneNumber],
    };

    return Yup.object().shape(validationShape);
  }, []);

  const onSubmit = () => {
    const { phoneNumber } = values;
    setSubmitting(true);
    updateUserPhone(phoneNumber.replace(/[-().+ ]/gi, ""));
  };

  const {
    values,
    touched,
    errors,
    setFieldValue,
    handleSubmit,
    isValid,
    setFieldTouched,
  } = useFormik({
    initialValues: initialFormValues,
    onSubmit,
    validationSchema: getValidationShape(),
    enableReinitialize: true,
    validateOnMount: true,
  });

  const isFormDisabled = !isValid || isEqual(values, initialFormValues);

  return (
    <ResponsiveModal
      show={showModal}
      onClose={handleModalVisibility}
      position="right"
      primaryButtonConfig={{
        title: submitting ? (
          <div className="button_content">
            <div> {translate("update")}</div>
            <div className="spinner" />
          </div>
        ) : (
          translate("update")
        ),
        type: "submit",
        isDisabled: isFormDisabled || submitting,
        clickHandler: handleSubmit,
      }}
      secondaryButtonConfig={{
        title: translate("cancel"),
        type: "button",
        clickHandler: handleModalVisibility,
      }}
      title={translate("mobile_two_fa_setup")}
      id="user_phone_edit_container"
    >
      <form onSubmit={handleSubmit}>
        <p>{translate("use_your_mobile_phone_number")}</p>
        <TextInput
          label={translate("mobile_phone_number")}
          placeholder={translate("phone_placeholder")}
          changeHandler={(value) =>
            setFieldValue(FIELD_ENUM.phoneNumber, value)
          }
          blurHandler={() => setFieldTouched(FIELD_ENUM.phoneNumber, true)}
          maxLength={12}
          errorMessage={
            touched[FIELD_ENUM.phoneNumber] && !!errors[FIELD_ENUM.phoneNumber]
              ? translate(errors[FIELD_ENUM.phoneNumber])
              : null
          }
          name={FIELD_ENUM.phoneNumber}
          id={FIELD_ENUM.phoneNumber}
          value={values[FIELD_ENUM.phoneNumber]}
        />
      </form>
    </ResponsiveModal>
  );
};

EditModalPhone.defaultProps = {
  showModal: false,
};

EditModalPhone.propTypes = {
  showModal: PropTypes.bool,
  handleModalVisibility: PropTypes.func.isRequired,
  updateUserPhone: PropTypes.func.isRequired,
};

export default EditModalPhone;
