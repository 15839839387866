import React from 'react';
import PropTypes from 'prop-types';

/* eslint-disable */

export default function ChevronUp(props) {
  const { width, height,color } = props;
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height}>
      <path
        d='M5.999 0a.693.693 0 01.492.2l5.3 5.3a.695.695 0 01-.983.983L5.999 1.677 1.185 6.49a.695.695 0 01-.983-.983l5.305-5.3A.692.692 0 015.999 0z'
        fill={color}
      />
    </svg>
  );
}


ChevronUp.propTypes = {
  width: PropTypes.string,
  heigth: PropTypes.string
};

ChevronUp.defaultProps = {
  width: '12',
  height: '6.695',
  color: '#051d33'
};
