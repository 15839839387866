import React from 'react';
import classNames from 'classnames';
import { removeLanguageFromUrl } from '../../util/helper';
import { footerLinks, stickyBlacklist } from './constants';

import './index.scss';

const Footer = ({ history, lang = 'en' }) => {
  const sticky_footer = !stickyBlacklist.some((blacklistItem) =>
    removeLanguageFromUrl(history.location.pathname)?.startsWith(blacklistItem),
  );
  return (
    <div
      className={classNames({
        moneris_footer: true,
        sticky_footer,
      })}
    >
      {footerLinks[lang?.toLowerCase() ?? 'en'].map(({ title, url }) => (
        <a href={url} target='_blank' rel='noreferrer' key={title}>
          {title}
        </a>
      ))}
    </div>
  );
};

export default Footer;
