
function responseParser(resp) {
  const {
    email,
    displayName,
    givenName,
    surname,
    mobilePhone,
    preferredLanguage,
    passwordExpirationDate,
    passwordLastChangedDate,
    mfa_method,
  } = resp;
  return {
    email,
    displayName,
    givenName,
    surname,
    mobilePhone,
    preferredLanguage,
    passwordExpirationDate,
    passwordLastChangedDate,
    mfa_method,
  };
}

/*eslint-disable */
function PhoneValidator(number) {
  if (
    number !== undefined &&
    number !== "" &&
    number !== null &&
    number.length > 9
  ) {
    const format = ["_", "_", "_", "-", "_", "_", "_", "-", "_", "_", "_", "_"];
    const len = number.length;
    if (len !== 0 && number !== undefined) {
      for (let i = 0; i < len; i++) {
        let break_loop = false;
        const char_value = number.charAt(i);
        if (
          char_value !== " " &&
          char_value != "(" &&
          char_value != ")" &&
          char_value != "-" &&
          char_value != "_"
        ) {
          format.forEach((val, index) => {
            if (val === "_" && !break_loop) {
              format[index] = char_value;
              break_loop = true;
            }
          });
        }
      }
    }
    const masked_number = format.toString().replace(/,/g, "");
    return masked_number;
  }
  return "";
}

export { PhoneValidator, responseParser };
