const messages = {
  en: {
    user_account: "User Account",
    security: "Security",
    go_back: "Go back",
    user_setting: "User Settings",
    go_to_moneris_portal: "Go to Moneris Portal",
  },
  fr: {
    user_account: "Compte d’utilisateur",
    security: "Sécurité",
    go_back: "Retour",
    user_setting: "Paramètres utilisateur",
    go_to_moneris_portal: "Accéder au portail Moneris",
  },
};

export default messages;
