/**
 * @typedef {{
 *  en: Object.<string, string>,
 *  fr: Object.<string, string>
 * }} Translation
 */

/**
 * Returns partial function that attempts to find the key in the ebject(s) passed to return
 * the tranlated value with respect to user lang in redux.
 * If unable to find, checks default message library.
 * If still unable to find the matching key, returns the key
 * @param {Translation} translateFiles
 * @returns {Function} Partial function
 * // Second function
 * @param {String} key
 * @returns {String} Translated string with regards to language
 */

export default function createTranslator(translation, lang = null) {
  let language = lang ?? window.localStorage.getItem("lang") ?? "en";
  language = language?.toLowerCase();
  return (key) => {
    return translation?.[language]?.[key] ?? key;
  };
}
