import * as Yup from "yup";

const phoneRegExp = /^\d{3}-?\d{3}-?\d{4}$/;

export const FIELD_ENUM = {
  phoneNumber: "phoneNumber",
};

export const validation = {
  [FIELD_ENUM.phoneNumber]: Yup.string()
    .trim()
    .min(10, "error_valid_phone")
    .matches(phoneRegExp, "error_valid_phone")
    .required("error_valid_phone"),
};
