/*eslint-disable*/
const error = {
  en: {
    page_moved: 'Sorry, looks like this page has moved.',
    home: 'Home'
  },

  fr: {
    page_moved: "Une erreur s'est produite. La page a été déplacée.",
    home: 'Accueil'
  }
};

export default error;
