import React from "react";
import LinkIcon from "./LinkIcon/index";
import ResponsiveLink from "@moneris-portal/responsive-link";

import "./index.scss";

const ResourceCard = ({
  loading = false,
  title,
  imageSrc,
  children,
  link,
  linkText,
  linkAnchorAttributes,
}) => (
  <div data-testid="resource_card" className="resource_card_container">
    <div>
      {imageSrc && (
        <img
          alt="resource_card_image"
          src={imageSrc}
          className="resource_card_image"
        />
      )}

      <div className="resource_card_content">
        {title && <h3 className="resource_card_title">{title}</h3>}
        {children && <div className="resource_card_children">{children}</div>}
      </div>
    </div>

    {link && linkText && (
      <div className="resource_card_link">
        <ResponsiveLink
          isDisabled={loading}
          linkText={linkText}
          link={link}
          linkAnchorAttributes={linkAnchorAttributes}
        >
          <LinkIcon />
        </ResponsiveLink>
      </div>
    )}
  </div>
);

export default ResourceCard;
