import React from "react";
import ResourceCard from "../ResourceCard/index";
import InsightImage from "./assets/Insight_Image.jpg";
import BookMockupEn from "./assets/Book_Mockup_EN.jpg";
import BookMockupFr from "./assets/Book_Mockup_FR.jpg";
import GiftCard from "./assets/GiftCard_1.png";
import createTranslator from "../../../util/translationHelper";
import messages from "./translations/messages";

import "./index.scss";

const ResourceCardRow = ({ lang, loading }) => {
  const translate = createTranslator(messages);

  return (
    <div className="resource_card_section">
      <h3>{translate("resources")}</h3>

      <div className="resource_cards">
        <ResourceCard
          loading={loading}
          imageSrc={lang === "en" ? BookMockupEn : BookMockupFr}
          title={translate("start_a_digital_storefront")}
          link={translate("start_a_digital_storefront_link")}
          linkText={translate("download_free_today")}
          linkAnchorAttributes={{
            rel: "noreferrer",
            target: "_blank",
          }}
        >
          <p>{translate("build_your_online_business")}</p>
        </ResourceCard>
        <ResourceCard
          loading={loading}
          imageSrc={InsightImage}
          title={translate("industry_insights_blog")}
          link={translate("industry_insights_blog_link")}
          linkText={translate("learn_now")}
          linkAnchorAttributes={{
            rel: "noreferrer",
            target: "_blank",
          }}
        >
          <p>{translate("stay_informed")}</p>
        </ResourceCard>
        <ResourceCard
          loading={loading}
          imageSrc={GiftCard}
          title={translate("exclusive_time_limited_offer")}
          link={translate("exclusive_time_limited_offer_link")}
          linkText={translate("unlock_your_offer")}
          linkAnchorAttributes={{
            rel: "noreferrer",
            target: "_blank",
          }}
        >
          <p>{translate("boost_your_sales")}</p>
        </ResourceCard>
      </div>
    </div>
  );
};

export default ResourceCardRow;
