const messages = {
  en: {
    invalid_message: "Something went wrong. Try again.",
    welcome_back:'Welcome back'
  },
  fr: {
    invalid_message: `Une erreur c'est produit. Veuillez essayer de nouveau.`,
    welcome_back:'Content de te revoir'
  },
};

export default messages;
