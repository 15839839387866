import React, { useCallback, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";
import createTranslator from "../../../../../util/translationHelper";
import ResponsiveModal from "@moneris-portal/responsive-modal";
import { TextInput } from "@moneris-portal/input";
import messages from "../../Translations/messages";
import { isEqual } from "../../../../../util/object-parser";
import { PhoneValidator } from "../../../util";
import { validation, FIELD_ENUM } from "./utils";

import "./index.scss";

const EditModalUserProfile = ({
  firstName,
  lastName,
  phoneNumber,
  phoneExtension,
  updateUserProfile,
  showModal,
  handleModalVisibility,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const translate = createTranslator(messages);

  const initialFormValues = {
    [FIELD_ENUM.firstName]: firstName ?? "",
    [FIELD_ENUM.lastName]: lastName ?? "",
    [FIELD_ENUM.phoneNumber]: PhoneValidator(phoneNumber) ?? "",
    [FIELD_ENUM.phoneExtension]: phoneExtension ?? "",
  };

  const getValidationShape = useCallback(() => {
    const validationShape = {
      [FIELD_ENUM.firstName]: validation[FIELD_ENUM.firstName],
      [FIELD_ENUM.lastName]: validation[FIELD_ENUM.lastName],
      [FIELD_ENUM.phoneNumber]: validation[FIELD_ENUM.phoneNumber],
      [FIELD_ENUM.phoneExtension]: validation[FIELD_ENUM.phoneExtension],
    };

    return Yup.object().shape(validationShape);
  }, []);

  const onSubmit = () => {
    const { firstName, lastName, phoneNumber, phoneExtension } = values;
    const userProfileObj = {
      contact_phone_ext: phoneExtension,
      first_name: firstName,
      last_name: lastName,
      contact_phone: phoneNumber.replace(/[-().+ ]/gi, ""),
    };
    updateUserProfile(userProfileObj);
    setSubmitting(true);
  };

  const {
    values,
    touched,
    errors,
    setFieldValue,
    handleSubmit,
    isValid,
    setFieldTouched,
  } = useFormik({
    initialValues: initialFormValues,
    onSubmit,
    validationSchema: getValidationShape(),
    enableReinitialize: true,
    validateOnMount: true,
  });

  const isFormDisabled = !isValid || isEqual(values, initialFormValues);

  const fieldList = [
    {
      key: FIELD_ENUM.firstName,
      placeholder: "eg. John",
      title: "user_first_name",
    },
    {
      key: FIELD_ENUM.lastName,
      placeholder: "eg. Smith",
      title: "user_last_name",
    },
    {
      key: FIELD_ENUM.phoneNumber,
      placeholder: "phone_placeholder",
      title: "contact_phone_number",
      maxLength: 12,
    },
    {
      key: FIELD_ENUM.phoneExtension,
      title: "ext",
      maxLength: 4,
      isDisabled: values[FIELD_ENUM.phoneNumber] === "",
    },
  ];

  return (
    <ResponsiveModal
      show={showModal}
      onClose={handleModalVisibility}
      position="right"
      primaryButtonConfig={{
        title: submitting ? (
          <div className="button_content">
            <div> {translate("update")}</div>
            <div className="spinner" />
          </div>
        ) : (
          translate("update")
        ),
        type: "submit",
        isDisabled: isFormDisabled,
        clickHandler: handleSubmit,
      }}
      secondaryButtonConfig={{
        title: translate("cancel"),
        type: "button",
        clickHandler: handleModalVisibility,
      }}
      title={translate("user_profile")}
      id="user_account_edit_modal"
    >
      <form onSubmit={handleSubmit}>
        {fieldList.map(({ title, key, placeholder, maxLength, isDisabled }) => (
          <div key={key} className="body_cell">
            <TextInput
              name={key}
              id={key}
              value={values[key]}
              changeHandler={(value) => setFieldValue(key, value)}
              blurHandler={() => setFieldTouched(key, true)}
              placeholder={
                typeof placeholder === "string"
                  ? translate(placeholder)
                  : undefined
              }
              errorMessage={
                touched[key] && !!errors[key] ? translate(errors[key]) : null
              }
              label={translate(title)}
              maxLength={maxLength}
              isDisabled={!!isDisabled}
            />
          </div>
        ))}
      </form>
    </ResponsiveModal>
  );
};

EditModalUserProfile.defaultProps = {
  showModal: false,
  errorCode: null,
};

EditModalUserProfile.propTypes = {
  showModal: PropTypes.bool,
  errorCode: PropTypes.any,
  updateUserProfile: PropTypes.func.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  extension: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  handleModalVisibility: PropTypes.func.isRequired,
};

export default EditModalUserProfile;
