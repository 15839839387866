import * as Yup from "yup";

export const FIELD_ENUM = {
  mfaCode: "mfaCode",
};

export const validation = {
  [FIELD_ENUM.mfaCode]: Yup.string()
    .trim()
    .required("error_invalid_code"),
};
