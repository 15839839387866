/*eslint-disable*/
const error = {
  en: {
    title:'Your session has expired',
    wrong: "Your session has expired,",
    try_again: "sign in again",
    try_again_button: "Try again",
  },

  fr: {
    title:'Votre session a expiré',
    wrong: "Votre session a expiré,",
    try_again: "connectez-vous à nouveau.",
    try_again_button: "Réessayer",
  },
};

export default error;
