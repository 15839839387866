const env = {
  dev: "dev",
  qa: "qa",
  prod: "prod",
};

const nonSsoUrlMap = {
  md2: {
    dev: "https://www1-dev.corp.moneris.com",
    qa: "https://www1-qa.moneris.com",
    prod: "https://www2.moneris.com",
  },
};

const getNonSsoUrl = (redirectTo) => {
  const nonSsoUrlMember = Object.prototype.hasOwnProperty.call(
    nonSsoUrlMap,
    redirectTo
  )
    ? nonSsoUrlMap[redirectTo]
    : nonSsoUrlMap.md2;

  const { host = "" } = window?.location ?? {};
  let tempEnvironment;
  if (/-dv/.test(host) || /localhost/.test(host)) {
    tempEnvironment = env.dev;
  } else if (/-qa/.test(host)) {
    tempEnvironment = env.qa;
  } else {
    tempEnvironment = env.prod;
  }

  return nonSsoUrlMember[tempEnvironment] ?? "";
};

export default getNonSsoUrl;
